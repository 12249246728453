.main{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: #eaeaea;
    overflow: hidden;
}

.container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100vh;
    background: #f5f5f5;
    /* box-shadow: 0 30px 50px #dbdbdb; */
}

.container .slide .item{
    width: 220px;
    height: 350px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 10px;
    /* box-shadow: 0 30px 50px #505050; */
    background-position: 50% 50%;
    background-size: cover;
    display: inline-block;
    transition: 0.5s;
}
.container .slide .subTitle{
    font-size: 20px;
    color: black;
}

.slide .item:nth-child(1),
.slide .item:nth-child(2){
    top: 0;
    left: 0;
    transform: translate(0, 0);
    border-radius: 0;
    width: 100%;
    height: 100%;
}


.slide .item:nth-child(3){
    left: 60%;
}
.slide .item:nth-child(4){
    left: calc(50% + 420px);
    height: 280px;
}
.slide .item:nth-child(5){
    left: calc(50% + 670px);
    height: 280px;
}

.slide .item:nth-child(n + 6){
    left: calc(50% + 660px);
    opacity: 0;
}



.item .content{
    position: absolute;
    top: 50%;
    left: 150px;
    width: 300px;
    text-align: left;
    color: #eee;
    transform: translate(0, -50%);
    font-family: system-ui;
    display: none;
}


.slide .item:nth-child(2) .content{
    display: block;
}


.content .name{
    font-size: 80px;
    text-transform: uppercase;
    font-weight: bold;
    opacity: 0;
    animation: animate 1s ease-in-out 1 forwards;
}

.content .des{
    margin-top: 10px;
    margin-bottom: 20px;
    opacity: 0;
    animation: animate 1s ease-in-out 0.3s 1 forwards;
}

.content button{
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    opacity: 0;
    animation: animate 1s ease-in-out 0.6s 1 forwards;
    border-radius: 20px;
}


@keyframes animate {
    from{
        opacity: 0;
        transform: translate(0, 100px);
        filter: blur(33px);
    }

    to{
        opacity: 1;
        transform: translate(0);
        filter: blur(0);
    }
}



.button{
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 20px;
}

.button button{
    width: 20px;
    height: 40px;
    border-radius: 80%;
    cursor: pointer;
    transition: 0.3s;
}

.button button:hover{
    color: #fff;
    transform: scale(1.1);
}