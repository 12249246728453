.summaryContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  .itinerary {
    width: 60%;
    margin-bottom: 20px;
  }
  
  .itinerary p {
    background: #f5f5f5;
    padding: 10px;
    border-radius: 20px;
    text-align: left;
  }
  
  .location {
    margin: 20px 0;
    background: #f5f5f5;
    padding: 10px;
    border-radius: 30px;
  }
  
  .location h3 {
    background: #3498db;
    padding: 10px;
    border-radius: 30px;
    text-align: left;
    margin: 0;
  }
  
  .day {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .day span {
    font-weight: bold;
  }
  
  .pricing {
    width: 30%;
    background: #f5f5f5;
    padding: 20px;
    border-radius: 30px;
    text-align: center;
  }
  
  .price {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .discountedPrice {
    font-size: 24px;
    color: #e74c3c;
  }
  
  .originalPrice {
    text-decoration: line-through;
    color: #7f8c8d;
  }
  
  .discount {
    color: #27ae60;
  }
  
  .paymentButton {
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .paymentButton:hover {
    background-color: #2980b9;
  }
  
  .coupons {
    margin-top: 20px;
  }
  
  .coupons h4 {
    margin-bottom: 10px;
  }
  
  .coupon {
    background: #ecf0f1;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .coupon span {
    display: block;
  }
  
  .applyCoupon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ecf0f1;
    padding: 10px;
    border-radius: 5px;
  }
  
  .apply-coupon input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .availableCoupons {
    margin-top: 10px;
  }
  
  .availableCoupons .coupon {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .availableCoupons .coupon button {
    padding: 5px 10px;
    background-color: #e74c3c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .availableCoupons .coupon button:hover {
    background-color: #c0392b;
  }
  