.tourContainer {
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .tourInfo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px 0;
  }
  
  .tourInfo span {
    background: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
  }
  
  .tourGallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: 20px;
  }
  
  .tourGallery img {
    width: 100%;
    border-radius: 20px;
  }
  
  .viewGallery {
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .viewGallery:hover {
    background-color: #ff3b3b;
  }
  