
.navbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    background-color: transparent;
    color: #000;
    z-index: 10;
  }
  
  .logo {
    font-size: 2.5rem;
    font-weight: 600;
  }
  
  .loginButton {
    padding: 0.5rem 1rem;
    /* background-color: #90EE90; */
    color: #000;
    cursor: pointer;
    border-radius: 20px;
  }
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .logo {
      margin-bottom: 1rem;
      color: #000;
    }
  }
  